<template>
    <div class="text-page">
        <router-view></router-view>
        <TheFooter />
        <BtnScrollToTop />
    </div>
</template>

<script>
    import TheFooter from '@/components/TheFooter'
    import BtnScrollToTop from '@/components/BtnScrollToTop'

    export default {
        components: {
            TheFooter,
            BtnScrollToTop
        }
    }
</script>

<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';

.text-page {
    width: 800px;
    margin: 20px auto 20px auto;

    @media (max-width: map-get($breakpoints, sm)) {
        width: auto;
    }

    .legal-title {
        font-size: 35px;
        font-weight: 600;
        text-align: center;
        margin: 0px 0px 25px 0px;

        @media (max-width: map-get($breakpoints, sm)) {
            font-size: 25px;
            margin: 0px 20px 25px 20px;
        }
    }

    .legal-block {
        margin: 0px 0px 25px 0px;

        .block-title {
            font-size: 21px;
            font-weight: 600;
            margin: 0px 0px 10px 0px;
        }
        .block-subtitle {
            font-size: 18px;
            font-weight: 600;
            margin: 0px 0px 10px 0px;
        }
        .block-li {
            margin-left: 20px;
        }
        .block-text {

            p {
                text-align: justify;
                margin: 0px 0px 20px 0px;
            }
            table {
                width: 100%;
                margin: 0px 0px 20px 0px;
                border-collapse: collapse;
            }
            th {
                border: 1px solid #dddddd;
                text-align: center;
                padding: 8px;
            }
            td {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
            }
        }
    }
}
</style>
